.image-area {
  border-radius: 20px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-area img {
  margin: auto;
}
.creators-title {
  color: #fff;
  text-align: center;
  margin: 60px;
}
.card-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-wrapper {
  width: 270px;
  height: 360px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.img-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 180%;
  height: 100%;
  width: 100%;
  background: #00b4d8;
  z-index: 1;
  transform: skew(45deg);
  transition: 0.5s;
}

.img-wrapper:hover:before {
  left: -180%;
}

.img-wrapper img {
  height: 360px;
  width: 272px;

  transition: 2s;
}
.img-wrapper:hover img {
  filter: grayscale(0%);
  transform: scale(1.1);
}

.img-wrapper h2 {
  background: #00b4d8;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: perspective(400px) rotateY(90deg);
  transform-origin: right;
  transition: 1s;
}

.img-wrapper:hover h2 {
  transform: perspective(400px) rotateY(0deg);
}

.img-wrapper ul {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  background: rgba(255, 255, 255, 0);
}

.img-wrapper ul li {
  background: #333;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  transform: perspective(800px) rotateY(90deg);
  transition: 0.5s;
  transform-origin: left;
}

.img-wrapper:hover ul li {
  transform: perspective(800px) rotateY(0deg);
}

.img-wrapper:hover ul li:nth-child(1) {
  transition-delay: 0.2s;
}

.img-wrapper:hover ul li:nth-child(2) {
  transition-delay: 0.6s;
}

.img-wrapper:hover ul li:nth-child(3) {
  transition-delay: 0.8s;
}

.img-wrapper:hover ul li:nth-child(4) {
  transition-delay: 1s;
}

.img-wrapper ul li a {
  color: #00b4d8;
  background: rgba(255, 255, 255, 0);
}

.img-wrapper ul li i {
  color: #00b4d8;
  background: rgba(255, 255, 255, 0);
}

.img-wrapper ul li i:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0);
}

.img-wrapper ul li img {
  display: inline-block;
  color: #00b4d8;
  background: rgba(255, 255, 255, 0);
}

.img-wrapper ul li img:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0);
}

.creators-text {
  margin: 20px 10%;
  background-color: orange;
  border-radius: 20px;
  padding: 10px;
}

.creators-text h2 {
  text-align: left;
  font-size: 1.5rem;
  letter-spacing: 1.5px;
  word-spacing: 2.5px;
  line-height: 30px;
}

.buy-section {
  height: 20vh;
  width: 80vw;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 15px;
}

.buy-section p {
  color: #000;
  margin: 30px;
}
.buy-section button {
  color: #fff;
  letter-spacing: 1px;
  background-color: #00b4d8;
  font-size: 1.3rem;
  font-weight: 1000;
  border: none;
  padding: 15px;
  width: 140px;
  border-radius: 15px;
  cursor: pointer;
  margin: 10px;
}

button:hover {
  color: #00b4d8;
  background-color: #fff;
  animation: shake 0.5s;
}

.buy-section h2 {
  color: #000;
  letter-spacing: 2px;
  word-spacing: 4px;
  font-size: 1.5rem;
  margin-left: 20px;
}

.buy-section h2 span {
  text-decoration: underline;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px) rotate(-5deg);
  }

  50% {
    transform: translateX(-15px) rotate(5deg);
  }
  100% {
    transform: translateX(0px);
  }
}
@media (max-width: 800px) {
  .buy-section {
    padding: 10px;
  }
  .IDunnoHowThisWorks{
    display: none;
  }
}

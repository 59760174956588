.minting {
  margin-top: 100px;
 
align-content: center;

margin-left: 10vw;
margin-right: 10vw;
display: flex;
align-items: center;

  justify-content: space-around;
  border-radius: 30px;
 margin-bottom: 200px;
}

.connect-wallet-button {
  color: white;
  border-radius: 10px;
  font-size: 40px;
  cursor: pointer;
padding:5px;
border: none;
background-color: purple;
}

.m-title {
  color: white;
 
}
.AlpacaHead{
  height: 350px;
  width:auto;
border-radius: 20px;
position: absolute;
z-index: -1;
margin-bottom: 30px;
}
.freeNFT{
font-size: 45px;
color: white;
font-weight: bold;
}
.freeNFT2{
  font-size: 45px;
  color: white;
  font-weight: bold;
  display: none;
  }
.spaceAround{
  display: flex;
  margin-top: 30px;
  justify-content: space-around;
}
.AlpacaHead2{
  display: none;
  height: 350px;
  width:auto;
border-radius: 20px;
position: absolute;
z-index: -1;
margin-bottom: 30px;
}
@media (max-width: 1200px) {
  
  .AlpacaHead{
height: 300px;
    
  }
  .freeNFT{
    font-size: 35px;

    }
    .connect-wallet-button {

      font-size: 30px;
  
    }
}
@media (max-width: 900px) {
  
  .AlpacaHead{
height: 220px;
    
  }
  .freeNFT{
    font-size: 30px;

    }
    .connect-wallet-button {

      font-size: 27px;
  
    }
}
@media (max-width: 900px) {
  
  .AlpacaHead{
display: none;
    
  }
  .AlpacaHead2{
    display: block;
        
      }
  .freeNFT{
    font-size: 30px;
display: none;
    }
    .connect-wallet-button {

      font-size: 27px;
  
    }
    .freeNFT2{
      display: block;
      font-size: 30px;
    }
}
@media (max-width: 700px) {
  .minting {
    margin-top: 100px;
  }
  .AlpacaHead{
display: none;
    
  }
  .AlpacaHead2{
    display: block;
    height: 300px;
      }
  .freeNFT{
    font-size: 30px;
display: none;
    }
    .connect-wallet-button {

      font-size: 27px;
  
    }
    .freeNFT2{
      display: block;
      font-size: 30px;
    }
}
@media (max-width: 450px) {
  .minting {
    margin-top: 150px;
  }
}
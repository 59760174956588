.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #fff;
  background-color: black;
}

.f-icons {
  color: white;
  padding: 5px;
  cursor: pointer;
}

.f-icons:hover {
  color: #00b4d8;
}

.footer img {
  height: 64px;
  margin: 10px;
}

.copyright {
  color: white;
}

@import url("https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inspiration&display=swap");
.StoryTextContainer {
  margin-top: 20px;
  margin-left: 9.5vw;
  margin-right: 9.5vw;

  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}
.StoryText {
  color: #bfc500;

  font-size: 1.4em;
  font-weight: lighter;
  letter-spacing: 2px;
  margin-right: 30px;
  padding: 20px;
}
.StoryTitle {
  color: #bfc500;
  font-size: 2.4em;
  margin-left: auto;
  margin-right: auto;
}
.StoryDivided {
  display: flex;
}
.StoryImages1 {
  width: 14.6vw;
  height: 14.6vw;
  border-top-left-radius: 20px;
}
.StoryImages2 {
  width: 14.6vw;
  height: 14.6vw;
  border-top-right-radius: 20px;
}
.StoryImages3 {
  width: 14.6vw;
  height: 14.6vw;
  border-bottom-left-radius: 20px;
}
.StoryImages4 {
  width: 14.6vw;
  height: 14.6vw;
  border-bottom-right-radius: 20px;
}
.StoryImages {
  width: 14.6vw;
  height: 14.6vw;
}
.StoryFourImages {
  margin: 15px;
  width: 400px;
  height: 400px;
  justify-content: center;
  display: grid;
  grid-template-columns: 200px 200px;
  grid-row: auto auto;
  grid-column-gap: 45px;
  grid-row-gap: 20px;
}

@media (max-width: 800px) {
  .StoryFourImages {
    display: none;
  }
  .StoryText {
    margin-right: 0px;
  }
  .creators-title {
    margin: 0px;
  }
}

.back-home {
  color: #fff;
  background-color: #00b4d8;
  font-size: 16px;
  margin: 3vh;
  font-weight: 1000;
  border: none;
  padding: 15px;
  width: 140px;
  border-radius: 15px;
  cursor: pointer;
}

.back-home:hover {
  color: #00b4d8 !important;
  background-color: #fff;
  animation: shake 0.5s;
}

.RoadMap-background {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Roadmap-Plan{
  background-color: green;
  color: white;
  border-radius: 20px;
  font-size: 1.7rem;
  margin-left: 10vw;;
  letter-spacing: 2px;
    word-spacing: 3px;
    line-height: 35px;
    padding: 20px;
}
.RoadMap-Text {
  padding-left: 10vw;
  color: white;
  margin-top: 0px;
  font-weight: lighter;
  font-size: 1.4em;
  display: flex;

  align-items: center;
}
.RoadMap-blue {
  color: #00b4d8;
  font-size: 1.6em;
}
.RoadMap-investments {
  margin-left: 3vw;
  margin-top: 20px;
  margin-bottom: 20px;
}
.RoadMap-image {
  width: auto;
  height: 600px;
  padding-right: 10vw;
}
.RoadMap-Title {
  font-size: 2rem;
  color: white;
  margin-top: 0px;
  padding-left: 10vw;
}
.RoadMap-Whole-Background {
  margin-top: 100px;
}


@media (max-width: 1110px) {
.Roadmap-Plan{
  font-size: 1.7rem;
  margin-left: 10vw;;
  letter-spacing: 1.7px;
  word-spacing: 2.7px;
  line-height: 32px;
}
}
@media (max-width: 935px) {
  .Roadmap-Plan{
    font-size: 1.5rem;
    margin-left: 10vw;;
    letter-spacing: 1.5px;
    word-spacing: 2.5px;
    line-height: 30px;
  }
}

@media (max-width: 880px) {
  .RoadMap-image {
    width: auto;
    height: 1000000px;
    padding-right: 10vw;
    display: none;

  }
  .RoadMap-blue{
    font-size: 25px;
  }
  .RoadMap-investments{
    font-size: 15px;
  }
  .Roadmap-Plan{
    margin-right: 10vw;
    margin-bottom: 35px;
  }
}

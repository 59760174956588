.homepage {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage h1 {
  color: #fff;
  text-align: center;
  margin: auto 1rem;
  position: absolute;
  font-size: 2rem;
  top: 8vh;
  animation: fade ease 2s;
}

.clubs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.club-logo {
  width: 156px;
  height: 156px;
  /* border: 1px solid #fff;
  border-radius: 100%; */
}

.enter-btn {
  border: none;
  border-radius: 10px;
  color: #000;
  letter-spacing: 1px;
  font-size: large;
  background-color: #bfc500;
  padding: 15px 50px;
  margin: 20px;
  cursor: pointer;
}

.enter-btn:hover {
  background-color: #fff;
  color: #000;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(-20px);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  background-color: #251D3A;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100vw;
  animation: fadeInAnimation ease 3s;
  transition: ease 1s;
}

.hidden {
  position: fixed;
  top: -100px;
  width: 100vw;
  animation: fadeInAnimation ease 3s;
  transition: ease 1s;
}

.nav {
  display: flex;
}

.navbar-item {
  padding: 5px;
  cursor: pointer;
}

.navbar-item2 {
  padding: 5px;
  cursor: pointer;
}
.navbar-item2:hover {
  color: #90e0ef;
}
.navbar-item:hover {
  color: #90e0ef;
}

.icons {
  margin: 30px 10px;
  color: #fff;
  cursor: pointer;
}

.icons:hover {
  color: #90e0ef;
}

.title {
  color: #fff;
  margin-left: 35px;
}
.logo {
  display: none;
}
.navbar-item {
  text-decoration: none;
  display: flex;
  color: #fff;
  margin: 22px 5px;
}

.navbar-item:hover {
  color: #00b4d8;
}

.navbar-item2 {
  text-decoration: none;
  display: flex;
  color: #fff;
  margin: 22px 5px;
}

.navbar-item2:hover {
  color: #00b4d8;
}

@media (max-width: 800px) {
  .title {
    display: none;
  }
  .logo {
    display: block;
    width: 50px;
    height: 50px;
    margin-top: 10px;
    margin-left: 10px;
  }
  .navbar-item2 {
    display: none;
  }
  .icons {
    display: none;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.welcome-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

}

.welcome-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 120px;
  text-align: center;
}

.welcome-section img {
  border-radius: 10%;
  margin-top: 20px;
  margin-left: 200px;
}

.welcome-section p {
  color: #fff;
  text-align: left;
  font-size: 1.5rem;
  margin-left: 40px;
}
.welcome-section button {
  color: #fff;
  background-color: #00b4d8;
  font-size: 16px;
  font-weight: 1000;
  border: none;
  padding: 15px;
  width: 140px;
  border-radius: 15px;
  cursor: pointer;
}

.welcome-left span {
  float: right;
  padding-right: 30px;
  padding-top: 10px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.welcome-left span:hover {
  color: #00b4d8;
}

.welcome-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome-section button:hover {
  color: #00b4d8 !important;
  background-color: #fff;
  animation: shake 0.5s;
}

.wc-icons {
  margin-top: 60px;
  color: white;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px) rotate(-5deg);
  }

  50% {
    transform: translateX(-15px) rotate(5deg);
  }
  100% {
    transform: translateX(0px);
  }
}

@media (max-width: 800px) {
  .DataKaiRaa {
    border-radius: 10%;
    margin-top: 20px;
    margin-left: 200px;
    display: none;
  }
  .welcome-section p {

    color: #fff;
    text-align: left;
    font-size: 40px;
    
    margin-left: 20px !important;
  }
  .welcome-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    text-align: center;
  }
  .row {
  }
}

@media (max-width: 400px) {
  .welcome-section p {
    margin-top: 170px;
    font-size: 35px;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background: linear-gradient(to bottom, #33ccff 0%, #03045e 100%);
  background-color: #000000;
  background-image: url("./images/stars.webp");

  margin: 0;
  font-family: "Fredoka One", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: #00b4d8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  font-family: "Fredoka One", cursive;
  background-color: #00b4d8;
}

.row {
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
